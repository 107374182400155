// @flow

import React from 'react'
import queryString from 'query-string'

import withTheme from 'hoc/withTheme'

import { fetchLead, patchLead } from 'Actions'
import { Store } from 'Store'

import styles from './styles.module.scss'

import themes, { type Theme } from './themes/__supportedThemes'

type Props = {
    history: any,
    location: any,
    match: any,
    theme: Theme
};

function Landing (props: Props) {
    const { dispatch } = React.useContext(Store)
    const [formState, setFormState] = React.useState(null)

    const handleSubmit = React.useCallback(async e => {
        e.preventDefault()
        setFormState('loading...')

        try {
            let code = e.target.elements['code'].value
            await fetchLead(dispatch, code)
            await patchLead(dispatch, code, {}, true)
        } catch (err) {
            console.error(err)
            setFormState(null)
            return
        }

        const response = await fetch('/shift-playbook.pdf')
        if (!response.ok) {
            console.error(await response.text())
            alert('Unable to download playbook.')
            return
        }

        const blob = await response.blob()

        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = 'Shift Marketing Giving Pages Product Sheet.pdf'
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() // afterwards we remove the element again

        setFormState('Success')
    }, [ dispatch ])

    return (
        <div className={styles.container}>
            <aside className={styles.leftSide}>
                <a className={styles.logoContainer} href='https://shiftmarketing.io/' target='_blank' rel="noopener noreferrer">
                    <div className={styles.logo} />
                </a>

                <div className={styles.img} />
            </aside>

            <div className={styles.rightSide}>

                <div className={styles.logoMobile}>
                    <a className={styles.logoContainer} href='https://shiftmarketing.io/' target='_blank' rel="noopener noreferrer">
                        <div className={styles.logo}/>
                    </a>
                </div>

                <h1 className={styles.title}>
                    Join the early access waitlist today.
                </h1>

                <span className={styles.info}>
                    Get a <strong>free</strong> product sheet with <br /> your waitlist reservation.
                </span>

                <form className={styles.form} onSubmit={handleSubmit}>
                    <input
                        name='code'
                        type="text"
                        placeholder="Enter your code"
                        defaultValue={queryString.parse(window.location.hash)['code']}
                    />
                    <button disabled={formState !== null}>
                        {formState || 'Join waitlist'}
                        <div className={styles.icon}/>
                    </button>
                </form>

                <div className={styles.information}>
                    <strong>What you can expect:</strong>
                    <p>Personalized video thank you to donors</p>
                    <p>A perfect mobile experience</p>
                    <p>Real-time, detailed analytics and reporting</p>
                    <p>Micro CRM</p>
                </div>

                <footer className={styles.footer}>
                    <span>
                        Shift Marketing Studio Inc. © 2021, All rights reserved.
                    </span>
                    <span>
                        Canada: PO Box 66031 Town Centre, Pickering, ON L1V6P7   |   203 - 1737 Union Street, Schenectady, NY 12309
                    </span>
                </footer>
            </div>
        </div>
    )
}

export default withTheme(themes)(Landing)
